.servicebutton {
    background-color: white;
    border: 1px solid rgba(191, 191, 191, 1);
    border-radius: 30px;
}

.servicebutton .buttoncontent {
    color: #4D4D4D;
}

.servicebutton:hover {
    background-color: #2EB2A6;
    border: 0px solid rgba(191, 191, 191, 1);
}

.servicebutton:hover .buttoncontent {
    color: white;
}