.navButton .downLine {
    display: none;
}


.navButton:hover .downLine {
    display: block;
}


.navButton .navButtonContent {
    color: white;
}

.navButton:hover .navButtonContent {
    color: #2EB2A6;
}

.subMenu {
    color: white
}

.subMenu:hover {
    color: #2EB2A6
}