.partaner {
    /*transition-property: all;*/
    transition-duration: 0.5s;
    transition-timing-function: ease;
    background-image: url("../../assets/img/introduce/爱之家.png");
    transform: scale(1);
}

.partaner:hover {
    transform: scale(1.08);
    background-image: url("../../assets/img/introduce/爱之家2.png");
}


.partaner1 {
    /*transition-property: all;*/
    background-image: url("../../assets/img/introduce/车300.png");
    transform: scale(1);
}

.partaner1:hover {
    transform: rotate(40deg);
    -o-transform: rotate(40deg);
    -webkit-transform: rotate(40deg);
    -moz-transform: rotate(40deg);
    filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=0.7660444431189777, M12=-0.6427876096865394, M21=0.6427876096865398, M22=0.7660444431189779)";
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transform: scale(1.08);
    background-image: url("../../assets/img/introduce/车300-2.png");
}

.partaner2 {
    /*transition-property: all;*/
    transition-duration: 0.5s;
    transition-timing-function: ease;
    background-image: url("../../assets/img/introduce/大公.png");
    transform: scale(1);
}

.partaner2:hover {
    transform: scale(1.08);
    background-image: url("../../assets/img/introduce/大公2.png");
}


.partaner3 {
    /*transition-property: all;*/
    transition-duration: 0.5s;
    transition-timing-function: ease;
    background-image: url("../../assets/img/introduce/济西.png");
    transform: scale(1);
}

.partaner3:hover {
    transform: scale(1.08);
    background-image: url("../../assets/img/introduce/济西2.png");
}


.partaner4 {
    /*transition-property: all;*/
    transition-duration: 0.5s;
    transition-timing-function: ease;
    background-image: url("../../assets/img/introduce/蚂蚁.png");
    transform: scale(1);
}

.partaner4:hover {
    transform: scale(1.08);
    background-image: url("../../assets/img/introduce/蚂蚁2.png");
}

.partaner5 {
    /*transition-property: all;*/
    transition-duration: 0.5s;
    transition-timing-function: ease;
    background-image: url("../../assets/img/introduce/帅车.png");
    transform: scale(1);
}

.partaner5:hover {
    transform: scale(1.08);
    background-image: url("../../assets/img/introduce/帅车2.png");
}

.partaner6 {
    /*transition-property: all;*/
    transition-duration: 0.5s;
    transition-timing-function: ease;
    background-image: url("../../assets/img/introduce/苏宁.png");
    transform: scale(1);
}

.partaner6:hover {
    transform: scale(1.08);
    background-image: url("../../assets/img/introduce/苏宁2.png");
}

.partaner7 {
    /*transition-property: all;*/
    transition-duration: 0.5s;
    transition-timing-function: ease;
    background-image: url("../../assets/img/introduce/天城.png");
    transform: scale(1);
}

.partaner7:hover {
    transform: scale(1.08);
    background-image: url("../../assets/img/introduce/天城2.png");
}

.partaner8 {
    /*transition-property: all;*/
    transition-duration: 0.5s;
    transition-timing-function: ease;
    background-image: url("../../assets/img/introduce/天天拍.png");
    transform: scale(1);
}

.partaner8:hover {
    transform: scale(1.08);
    background-image: url("../../assets/img/introduce/天天拍2.png");
}

.partaner9 {
    /*transition-property: all;*/
    transition-duration: 0.5s;
    transition-timing-function: ease;
    background-image: url("../../assets/img/introduce/维真.png");
    transform: scale(1);
}

.partaner9:hover {
    transform: scale(1.08);
    background-image: url("../../assets/img/introduce/维真2.png");
}

.partaner10 {
    /*transition-property: all;*/
    transition-duration: 0.5s;
    transition-timing-function: ease;
    background-image: url("../../assets/img/introduce/一号.png");
    transform: scale(1);
}

.partaner10:hover {
    transform: scale(1.08);
    background-image: url("../../assets/img/introduce/一号2.png");
}

.partaner11 {
    /*transition-property: all;*/
    transition-duration: 0.5s;
    transition-timing-function: ease;
    background-image: url("../../assets/img/introduce/永达.png");
    transform: scale(1);
}

.partaner11:hover {
    transform: scale(1.08);
    background-image: url("../../assets/img/introduce/永达2.png");
}

.partaner12 {
    /*transition-property: all;*/
    transition-duration: 0.5s;
    transition-timing-function: ease;
    background-image: url("../../assets/img/introduce/优信.png");
    transform: scale(1);
}

.partaner12:hover {
    transform: scale(1.08);
    background-image: url("../../assets/img/introduce/优信2.png");
}