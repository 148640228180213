.valueSender {
    border: 1px solid grey !important;
    width: 410px;
    height: 60px;
    padding-left: 15px;
    background-color: white;

}

.valueSender:focus {
    border: 1px solid #2EB2A6 !important;
    /*border-bottom: 1px solid #2EB2A6 !important;*/
    padding-left: 15px;
    box-shadow: none;
}

.valueReceive {
    border: 1px solid grey !important;
    width: 410px;
    height: 60px;
    padding-left: 15px;
    background-color: white;
}

.valueReceive:focus {
    border: 1px solid #2EB2A6 !important;
    /*border-bottom: 1px solid #2EB2A6 !important;*/
    padding-left: 15px;
    box-shadow: none;
}

.yanzhengma {
    border: 1px solid grey !important;
    width: 260px;
    height: 60px;
    padding-left: 15px;
}

.yanzhengma:focus {
    border: 1px solid #2EB2A6 !important;
    /*border-bottom: 1px solid #2EB2A6 !important;*/
    padding-left: 15px;
    box-shadow: none;
}