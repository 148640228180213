.processBlock {
    cursor: pointer;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transform: scale(1);
}

.processBlock:hover {
    transform: scale(1.08);
}