@keyframes phone-into-animation
{
    from {
        margin-left: 5px;
        opacity: 0;
    }
    to {
        margin-left: 75px;
        opacity: 1;
    }
}

@keyframes rotateInfinite {
    0% {
        transform:rotate(0deg);
    }
    50% {
        transform:rotate(180deg);
    }
    100% {
        transform:rotate(360deg);
    }
}
