.letterSelect span{
    cursor: pointer;
    padding: 5px;
    color: #181A1A;
    margin-right: 10px;
    display: block;
    float: left;
}

.letterSelect span:hover{
    color: white;
    background-color: #25A8A0;
}

.address span:hover{
    color: #25A8A0;
}
